<template>
  <div class="details-con" v-if="amend === 1">
    <!-- 第一部分 -->
    <div class="con-t">
      <p class="con-t-x"><span @click="handelClick"></span>质保详情</p>
      <div class="q-l-1" v-if="Object.keys(info).length>0">
        <el-form>
          <el-form-item label="商品名称:">
            {{ info.productName }}
          </el-form-item>

          <el-form-item label="关联订单号:">
            {{ info.orderNumber }}
          </el-form-item>

          <el-form-item label="发货时间:">
            {{ info.createdTime }}
          </el-form-item>

          <el-form-item>
            <div style="font-size: 18px; font-weight: 400; color: #333333">
              主机质保
            </div>
          </el-form-item>

          <el-form-item label="主机SN:">
            {{ info.masterSnCode }}
          </el-form-item>

          <el-form-item label="主机质保期:">
            <div>{{splitTime( info.qualityStartTime) }}~{{splitTime(info.qualityEndTime)  }}</div>
          </el-form-item>

          <el-form-item>
            <div style="font-size: 18px; font-weight: 400; color: #333333">
              物料质保
            </div>
          </el-form-item>

          <el-form-item>
            <el-table stripe :data="info.qualityDTOs" >
              <el-table-column
                label="物料类型"
                prop="materialType"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                label="物料名称"
                prop="materialName"
                min-width="100"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="物料属性"
                prop="materialAttr"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                label="数量"
                prop="materialCount"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                label="物料SN"
                prop="materialSn"
                width="100"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="质保期"
                width="200"
                align="center"
                show-overflow-tooltip
              >
                <template #default="{ row }">
                  {{splitTime(row.qualityStartTime)  }}~{{splitTime(row.qualityEndTime)  }}
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>

      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits,onMounted } from "vue";
import {qualityDetail} from "@/api/orderCenter"

const props=defineProps({
  rowData:{
    type:Object
  }
})
const amend = ref(1);
// 子组件传值给父组件
const emit = defineEmits(["changeId"]);


//质保时间不需要时分秒
const splitTime=(time)=>{
  if (!time){
    return
  }
  return time.split(' ')?.[0]
}

const handelClick = () => {
  emit("changeId", 1);
};
const info=ref({})

onMounted(()=>{
  qualityDetail(props.rowData).then(res=>{
     info.value=res.data
  })
})



</script>


<style scoped lang="less">
.details-con {
  .con-t {
    border-bottom: 1px solid #e1e1e1;
    margin-right: 35px;

    .con-t-x {
      font-size: 28px;
      font-weight: 400;
      color: #666666;
      margin-bottom: 20px;


      span {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-image: url("../../../assets/images/order/return2.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 8px;
        position: relative;
        top: 5px;
        left: 0;
        cursor: pointer;

        &:hover {
          background-image: url("../../../assets/images/order/return3.png");
          cursor: pointer;
        }
      }

      .q-l-1 {
        display: flex;
        justify-content: end;
        margin-right: 30px;

      }
    }
  }
}
</style>
