<template>
  <el-dialog
    v-model="dialogOpen"
    :show-close="false"
    width="30rem"
    height="268"
    style="padding:  16px 0"
  >
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <h4 :id="titleId" :class="titleClass" class="open1">提示</h4>
        <p @click="close" class="open2"></p>
      </div>
      <div class="jiagengxiang"></div>
    </template>
    <div v-if="info.invoiceMaterial == 1">
      <p class="KD" v-if="info.logisticsCompanyName">
        <span>快递公司：</span
        ><span>{{ info.logisticsCompanyName }}</span>
      </p>
      <p class="KD" v-else>
        <span></span><span>正在为您开具发票，请耐心等待！</span>
      </p>
      <p class="KD1" v-if="info.logisticsNumber">
        <span>快递单号：</span><span>{{ info.logisticsNumber }}</span>
      </p>
      <!-- <p class="KD1" v-else>
        <span></span><span>正在为您开具发票，请耐心等待！</span>
      </p> -->
    </div>
    <div v-else-if="info.invoiceMaterial==0">
      <p class="KD" v-if="info.invoiceUrl">
        <span
          >电子发票：
          <a target="_blank" :href="info.invoiceUrl">{{
              info.invoiceUrl
          }}</a></span
        >
      </p>
      <p class="KD" v-else>
        <span> <a target="_blank">正在为您开具发票，请耐心等待！</a></span>
      </p>
    </div>
    <div v-else>
      <p class="KD" v-if="info.invoiceUrl">
        <span
        >发票文件：
          <span class="invoice-link" @click="toLoad(info.invoiceUrl)">{{
              info.invoiceName
            }}</span></span
        >
      </p>
      <p class="KD" v-else>
        <span> <a target="_blank">正在为您开具发票，请耐心等待！</a></span>
      </p>
    </div>
    <template #footer>
      <div class="my-header">
        <el-button @click="openTitle" class="open3"> 确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits,onMounted } from "vue";
import Path from '@/global-variable'
const props= defineProps({
  checkValue: {
    type: Object,
  },
});
const dialogOpen = ref(false);
const info=ref()

onMounted(()=>{
  if (!props.checkValue){
    return
  }
  info.value= JSON.parse(JSON.stringify(props.checkValue))
  if (info.value.invoiceMaterial==='2'){
    info.value.invoiceUrl=Path.ossPath + '/order' +info.value.invoiceUrl
    let url=info.value.invoiceUrl
    info.value.invoiceName=url.split('/')[url.split('/').length-1]
  }
})

const toLoad=(url)=>{
   window.open(url)
}


let emits = defineEmits();
const openTitle = () => {
  console.log("123");
  emits("offTitle");
};
</script>

<style lang="less" scoped>
.invoice-link {
  cursor: pointer;
  color: blue; /* 超链接的颜色 */
  text-decoration: underline;
  text-decoration-color: #ce1200; /* 下划线是红色 */
  text-underline-offset: 3px; /* 调整下划线的偏移量 */
}

.invoice-link:hover {
  color: darkblue; /* 鼠标悬停时改变颜色 */
}


.jiagengxiang {
  width: 480px;
  height: 1px;
  background: #e1e1e1;
  border-radius: 1px 1px 0px 0px;
  margin-top: -12px;
}
.my-header {
  padding: 0 16px;
  box-sizing: border-box;
  height: 58px;
  // border-bottom: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;
  .open1 {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    margin-left: 5px;
  }
  .open2 {
    display: inline-block;
    margin-top: 5px;
    width: 14px;
    height: 14px;
    background-image: url("../../../assets//images/order/ch.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    &:hover {
      width: 30px;
      height: 30px;
      background-image: url("../../../assets//images/order/hch.png");
      margin-top: -8px;
      margin-right: -10px;
    }
  }
}
.open3 {
  width: 180px;
  height: 40px;
  background: #ce1200;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  margin: 0 auto;
  border: #ce1200;
}
.KD,
.KD1 {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
}
</style>
