<template>
    <div class="content" v-if="IdValue === 1">
        <!-- 头部 -->
        <div class="titletop">
            <div class="contract">我的合同</div>
            <div class="contractapply" @click="constractApply">合同申请</div>
        </div>
        <div class="order-input">
            <div class="order-content">
                <ul class="tabBox">
                    <li class="tabItem" v-for="(item, index) in orderContent" :key="item.id + index"
                        :class="item.id === currentActive ? 'active' : ''" @click.self="changeTab(item)">
                        {{ item.title }}
                    </li>
                </ul>
            </div>
            <!-- 搜索栏 -->
            <div class="content-input">
                <el-input v-focus v-model="keyCode" placeholder="输入合同编号、订单编号" class="input-with-select"
                    @keydown.enter="handelSearch(keyCode)" clearable @clear="handelClear">
                    <template #append>
                        <el-button class="input-icon" @click="handelSearch(keyCode)">
                            <img src="../../../assets//images/search.png" alt="" /></el-button>
                    </template>
                </el-input>
            </div>
        </div>
        <div class="content-theme" v-if="contractListInfo.length > 0" v-for="(item, index) in contractListInfo" :key="index">
            <div class="contract-1">{{item.type == 0 ? '纸质合同' : '电子合同'}}</div>
            <div class="contract-2">
                <img src="../image/order.png" style="width: 20px; height: 20px" />
                <div class="contract-2-1">{{item.status == 0 ? '处理中' : '已完成'}}</div>
            </div>
            <div class="contract-3">订单编号：{{item.orderNumber}}</div>
            <div class="contract-4">合同编号：{{item.contractNumber}}</div>
            <div class="contract-5">
                商品名称：{{item.productName}}
            </div>
            <div class="contract-6">购买数量：{{item.count}}</div>
            <div class="contract-7">合同金额：￥{{item.amount}}</div>
            <div class="contract-8">申请日期：{{item.applyDate}}</div>
            <div class="contract-9">甲方：</div>
            <div class="contract-9-1">{{item.partyA}}</div>
            <div class="contract-10">法定代表人：</div>
            <div class="contract-10-1">{{item.representative}}</div>
            <div class="contract-11">联系电话：</div>
            <div class="contract-11-1">{{item.phoneNumber}}</div>
            <div class="contract-12">地址：</div>
            <div class="contract-12-1">
                {{item.address}}
            </div>
            <div class="contract-13">
                注：乙方为平台方【武汉宁美数字科技有限公司】
            </div>
            <div class="contract-14" @click="lookcontract(item)" v-if="item.status == 1">查看合同</div>
            <div class="contract-15" @click="downloadcontract(item)" v-if="item.status == 1">下载合同</div>
            <div class="contract-15" @click="onlineConsumer" v-if="item.status == 0">联系客服</div>
        </div>
        <div v-else>
            <img src="../image/empty.png" style="margin-left: 260px;">
        </div>
        <orderCustomer v-model="showCustom" />
        <div class="demo-pagination-block" v-if="totalconlist.total">
            <el-pagination :current-page="totalconlist.pageNum" :page-size="totalconlist.size" :small="small" background
                layout="total, prev, pager, next, jumper" :total="totalconlist.total" next-text="下一页" prev-text="上一页"
                @current-change="handleCurrentChange1" />
            <el-button class="btn-cl">确定</el-button>
        </div>
        <div v-if="totalconlist.total === ''"></div>
    </div>
    <div class="dialog-list" v-loading.fullscreen.lock="loading">
        <el-dialog v-model="popUpList" style="width: 62%; height: 85%; --el-dialog-margin-top: 10vh" >
            <div v-if="addation == 0">
                <div style="display: flex; margin-left: 40px;margin-top: -5px;">
                    <div class="dialog-list-1">请选择需要签署合同的订单</div>
                </div>
                <div>
                    <div class="dialog-list-2">
                        <div class="dialog-list-2-1">选择</div>
                        <div class="dialog-list-2-2">订单编号</div>
                        <div class="dialog-list-2-3">商品名称</div>
                        <div class="dialog-list-2-4">购买数量</div>
                        <div class="dialog-list-2-5">订单状态</div>
                        <div class="dialog-list-2-6">订单金额</div>
                        <div class="dialog-list-2-7">创建时间</div>
                    </div>
                    <div class="dialog-list-3" v-for="(item,index) in tractlist" :key="index">
                        <div class="dialog-list-3-1" @click="checkcircle(item,index)">
                            <img v-if="circle == index" src="../image/ciclyactive.png">
                            <img v-else src="../image/cicly.png">
                        </div>
                        <div class="dialog-list-3-2">{{item.orderNumber}}</div>
                        <div class="dialog-list-3-3">{{item.productName}}</div>
                        <div class="dialog-list-3-4">{{item.count}}</div>
                        <div class="dialog-list-3-5">{{orderstatefilter[item.orderState]}}</div>
                        <div class="dialog-list-3-6">￥{{item.amount}}</div>
                        <div class="dialog-list-3-7">{{item.createTime}}</div>
                    </div>
                    <div class="dialog-list-4">
                        <el-pagination :current-page="orderLists.pageNum" :page-size="orderLists.size" :small="small"
                            background layout="total, prev, pager, next, jumper" :page-count="orderLists.pages" :total="orderLists.total"
                            next-text="下一页" prev-text="上一页" @current-change="handleCurrentChange" />
                    </div>
                </div>
            </div>
            <div v-if="addation == 1">
                <div style="display: flex; margin-left: 40px;margin-top: -5px;">
                    <div class="dialog-list-1">请选择您的合同信息</div>
                </div>
                <div class="add-text-plus">
                    <div class="add-text-plus1">
                        <div :class="defaltadd == index ? 'add-text-active' : 'add-text'" @click="defalutAddress(item,index)" v-for="(item, index) in addressList" :key="index">
                            <div class="hiddenImg"></div>
                            <div class="content-text">
                                <p class="dz">
                                <p class="dz1">客户类型：</p>
                                <p class="dz2">{{ item.type == 0 ? '企业客户' : '个人客户' }}</p>
                                </p>
                                <p class="ds">
                                <p class="ds1">甲方：</p>
                                <p class="ds2">{{ item.partyA }}</p>
                                </p>
                                <p class="ds">
                                <p class="ds1">法定代表人：</p>
                                <p class="ds2">{{ item.representative }}</p>
                                </p>
                                <p class="ds">
                                <p class="ds1">联系电话：</p>
                                <p class="ds2">{{ item.phoneNumber }}</p>
                                </p>
                                <p class="ds">
                                <p class="ds1">地址：</p>
                                <p class="ds3">
                                    {{ item.address }}</p>
                                </p>
                                <div class="default">
                                    <img v-if="item.isDefault == 1" src="../image/defalut.png">
                                    <img @click="setDefalut(item)" v-else src="../image/isdefalut.png">
                                </div>
                            </div>
                            <div class="det-cet">
                                <span @click="upDataItem(item)" class="det-cet-b">编辑</span>
                            </div>
                        </div>
                        <div class="plus" @click="showBox">
                            <img src="../../../assets/images/order/dz.png" alt="" />
                            <p>新增合同管理</p>
                        </div>
                    </div>
                    <img src="../image/shadow.png" class="add-text-plus2">
                </div>
            </div>
            <div v-if="addation == 2">
                <div style="display: flex; margin-left: 40px;margin-top: -5px;">
                    <div class="dialog-list-1">宁美数字销售合同</div>
                    <div class="dialog-list-11">合同编号：{{contractNumber}}</div>
                </div>
                <div class="add-text-sale">
                    <div class="add-text-sale-plus" v-html="tempcontent"></div>
                </div>
                <div class="add-text-sale-1">
                    <img v-if="check" src="../image/ciclyactive.png" @click="checkOk2" class="add-text-sale-2">
                    <img v-else src="../image/cicly.png" @click="checkOk1" class="add-text-sale-2">
                    <div class="add-text-sale-3">我已认真并仔细阅读销售合同内容，确认此销售合同</div>
                </div>
            </div>
            <div class="border-content">
                <div class="qOk1-1" v-if="addation == 1" @click="prewalk">上一步</div>
                <div class="qOk1-1" v-if="addation == 2" @click="prewalk1">上一步</div>
                <div class="qOk1" v-if="addation == 0" @click="popUpList = false">取消</div>
                <div class="qOk2" v-if="addation == 0" @click="nextwalk">下一步</div>
                <div class="qOk2-1" v-if="addation == 1" @click="nextwalk1">下一步</div>
                <div class="qOk2-1" v-if="addation == 2" @click="nextwalk2">确定</div>
            </div>
            <el-dialog v-model="isShowBox" :show-close="false" @close="clean" width="660">
                <template #header="{ close, titleId, titleClass }">
                    <div class="my-header">
                        <h4 :id="titleId" :class="titleClass" class="open1">{{ title }}</h4>
                        <span @click="close" class="open2"> </span>
                    </div>
                </template>
                <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="82px" class="demo-ruleForm"
                    label-position="left" status-icon>
                    <el-form-item label="客户类型" prop="type">
                        <el-select v-model="ruleForm.type" placeholder="请选择客户类型">
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="甲方" prop="partyA">
                        <el-input class="open4" v-model="ruleForm.partyA" placeholder="请填写甲方名称" />
                    </el-form-item>
                    <el-form-item label="法定代表人" prop="representative">
                        <el-input class="open4" v-model="ruleForm.representative" placeholder="请填写法定代表人" />
                    </el-form-item>
                    <el-form-item label="联系电话" prop="phoneNumber">
                        <el-input class="open4" v-model="ruleForm.phoneNumber" placeholder="请填写联系电话" />
                    </el-form-item>
                    <el-form-item label="地址" prop="address">
                        <el-input type="textarea" :rows="4" class="open6" v-model="ruleForm.address"
                            placeholder="请填写详细地址" @change="chooseAddress" />
                    </el-form-item>
                    <el-form-item style="margin-top: 18px">
                        <el-button class="open7" @click="RefMove()">取消 </el-button>
                        <el-button class="open8" @click="submitForm(ruleFormRef)">确定</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </el-dialog>
    </div>
    <OrderDetails @changeId="handleChangeId" :numValue="numValue" v-if="IdValue === 2"></OrderDetails>
    <ShippingAddress v-if="IdValue === 3" :amendValue="amendValue" @sureNextChangeId="sureNextValue" />
    <CheckTheInvoiceSh v-model="dialogOpen" :checkValue="checkValue" @offTitle="offTitle" />
</template>

<script setup>
    import { ref, onMounted, reactive } from "vue";
    import orderCustomer from "@/components/orderCustomer";
    import OrderDetails from "./OrderDetails";
    import ShippingAddress from "./ShippingAddress";
    import {
        orderList,
        Timeout,
        UpdateOrder,
        PayTran,
    } from "../../../api/orderCenter/index.js";
    import { contractlist,Deletecontract,contractConfirm,
        contractupdate,basiccontract,contractGenerate,contractList,contractOrderList } from "@/api/contract/index.js";
    import { ElMessage, ElMessageBox } from "element-plus";
    import CheckTheInvoiceSh from "./CheckTheInvoiceSh";
    import noContent from "../../../components/directives/noContent";
    import { useRouter } from "vue-router";
    import emitter from "@/utils/eventBus";
    const orderstatefilter = ref(['待支付', '已取消','待发货','已发货','已完成','待审核','已退货'])
    const keyCode = ref("");
    const currentActive = ref(1);
    const IdValue = ref(1);
    const addation = ref(0);
    const check = ref(false);
    const orderContent = ref([
        { title: "全部合同", num: "", id: 1 },
        { title: "处理中", num: 0, id: 2 },
        { title: "已完成", num: 1, id: 3 },
    ]);
    const dialogOpen = ref(false);
    const contractNumber = ref()
    const showCustom = ref(false);
    const popUpList = ref(false);
    emitter.on("confimCustomClose", (val) => {
        showCustom.value = false;
    });
    emitter.on("customClose", (val) => {
        showCustom.value = false;
        getgenerate();
    });
    const tempcontent = ref()
    const customMade = (id, inx, data) => {
        const token = localStorage.getItem("token");
        if (token) {
            showCustom.value = true;
            emitter.emit("customOrder", {
                customId: id,
                orderNumber: data.orderNumber,
                productList: data.productCustomizedMaterialPOS,
                proId: inx,
            });
        } else {
            emitter.emit("openLogin", { openLogin: true });
        }
    };
    const nextwalk = () => {
        if(!temporderInfo.value.id){
            ElMessage({
                type: "error",
                message: "请选择合同订单!",
            });
        }else{
            addation.value = 1
        }
    }
    const nextwalk1 = () => {
        if(tempadress.value.id){
            addation.value = 2
            let data = {
                orderNumber : temporderInfo.value.orderNumber,
                type : 0,
                basicContractId : tempadress.value.id
            }
            contractGenerate(data).then((res)=>{
                tempcontent.value = res.data.content
                contractNumber.value = res.data.contractNumber
            })
        }else{
            ElMessage({
                type: "error",
                message: "请选择合同地址",
            });
        }
    }
    const tractlist = ref([])
    const circle = ref(-1)
    // 订单详情
    const temporderInfo = ref({})
    const numValue = ref("");
    const handelCheckOut = (item) => {
        numValue.value = item.orderNumber;
        IdValue.value = 2;
    };
    // 子组件传递给父组件
    const handleChangeId = (i) => {
        IdValue.value = i;
        getgenerate();
    };
    const checkcircle = (val,inx) => {
        circle.value = inx
        temporderInfo.value = val
    }
    const ruleFormRef = ref();
    // 添加订单信息
    const sureNextValue = (i) => {
        IdValue.value = i;
        getgenerate();
    };
    const title = ref();
    // 我的订单列表
    const dataValue = reactive({
        pageNum: "",
        pageSize: "",
        orderState: "",
        queryWords: "",
    });
    const lookcontract = (data) => {
        window.open(data.sealContractUrl)
    }
    const orderData = ref([]);
    const orderLists = ref({});
    const constractorderList = ref({
        pageNumber : 1,
        pageSize : 14
    });
    const upDataItem = (item) => {
        clean();
        title.value = "修改合同信息";
        ruleForm.address = item.address;
        ruleForm.phoneNumber = item.phoneNumber;
        ruleForm.representative = item.representative;
        ruleForm.partyA = item.partyA;
        ruleForm.type = item.type;
        ruleForm.id = item.id;
        isShowBox.value = true;
    };
    const ruleForm = reactive({
        type: null,
        address: "",
        representative: "",
        phoneNumber: "",
        partyA: "",
    });
    const constractApply = () => {
        popUpList.value = true;
        addation.value = 0
        contractOrderList(constractorderList.value).then((res)=>{
            orderLists.value = res.data
            tractlist.value = res.data.list
        })
    };
    const prewalk = () => {
        addation.value = 0
    }
    const delItem = async (item) => {
        let res = await ElMessageBox.confirm("是否删除当前地址?", {
            confirmButtonText: "是",
            cancelButtonText: "否",
            type: "warning",
        }).catch((error) => {
            console.log(error);
        });
        if (res == "confirm") {
            console.log(item.id);
            let id = item.id;
            await DeleteAddress(id);
            getListAddress();
            ElMessage({
                type: "success",
                message: "删除成功",
            });
        }
    };
    const checkOk2 = () => {
        check.value = false
    }
    const downloadcontract = (data) => {
        const xhr = new window.XMLHttpRequest()
        xhr.open('GET', data.sealContractUrl, true)
        xhr.responseType = 'blob'
        xhr.send()
        xhr.onload = () => {
            if (xhr.status === 200) {
                const url = window.URL.createObjectURL(xhr.response)
                const a = document.createElement('a')
                a.style.display = "none";
                a.href = url
                a.download = '宁美数字销售合同.pdf'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                window.URL.revokeObjectURL(url)
            }
        }
    }
    const clean = () => {
        ruleForm.mobile = null;
        ruleForm.receiver = null;
        ruleForm.detail = null;
        ruleForm.address = [];
        return;
        ruleFormRef.value?.resetFields();
    };
    const options = reactive([
        {
            label : '企业客户',
            value : 0
        },
        {
            label : '个人客户',
            value : 1
        }
    ]); 
    const prewalk1 = () => {
        addation.value = 1
    }
    // 切换状态
    const changeTab = (item) => {
        currentActive.value = item.id;
        generatelist.value.status = item.num;
        getgenerate();
    };
    const onlineConsumer = () => {
        emitter.emit("onlineClient");
    }
    // 搜索
    const checkOk1 = () => {
        check.value = true
    }
    const searchEnterFun = (item) => {
        generatelist.value.param = item;
        getgenerate();
    };
    const rules = reactive({
        type: [{ required: true, message: "请选择客户类型", trigger: "blur" }],
        partyA: [{ required: true, message: "请填写甲方名称", trigger: "blur" }],
        phoneNumber: [
        { required: true, message: "请输入联系电话", trigger: "blur" },
        {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
        },
        ],
        representative: [{ required: true, message: "请填写法定代表人", trigger: "blur" }],
        address: [{ required: true, message: "请填写详细地址", trigger: "blur" }],
    });
    const showBox = () => {
        ruleForm.type = null;
        ruleForm.address = null;
        ruleForm.representative = null;
        ruleForm.phoneNumber = null;
        ruleForm.partyA = null;
        isShowBox.value = true;
        title.value = "新增合同信息";
    };
    const addressList = ref([]);
    const contractListInfo = ref([])
    const tempadress = ref({})
    const RefMove = () => {
        isShowBox.value = false;
        getListAddress();
    };
    const generatelist = ref({
        pageNum : 1,
        pageSize  : 5,
        param  : '',
        status : ''
    })
    const handleCurrentChange1 = (Num) => {
        generatelist.value.pageNum = Num;
        getgenerate();
    };
    const totalconlist = ref({})
    // 搜索按钮
    const handelSearch = (item) => {
        generatelist.value.param = item;
        getgenerate();
    };
    const getListAddress = async () => {
        try {
        let data = await contractlist();
        addressList.value = data.data;
        console.log(addressList.value, "数据");
        } catch (error) {
        console.log(error);
        }
    };
    // 清除
    const handelClear = () => {
        dataValue.queryWords = "";
        getgenerate(dataValue.queryWords);
    };
    const getgenerate = () => {
        contractList(generatelist.value).then((res) => {
            if(res.code == 200){
                totalconlist.value = res.data
                contractListInfo.value = res.data.list
            }
        })
    }
    const defalutAddress = (val,inx) => {
        tempadress.value = val
        defaltadd.value = inx
    }
    const handelsales = (item) => {
        emitter.emit("jumpsale", { orderNumber: item.orderNumber });
    };
    const loading = ref(false)
    const nextwalk2 = () => {
        if(check.value){
          loading.value = true
            let data = {
                orderNumber : temporderInfo.value.orderNumber,
                type : 0,
                contractNumber : contractNumber.value,
                basicContractId : tempadress.value.id
            }
            contractConfirm(data).then((res) => {
                loading.value = false
                if(res.code == 200){
                    ElMessage({
                        type: "success",
                        message: '合同申请成功!',
                    });
                    popUpList.value = false;
                    getgenerate();
                }else{
                    ElMessage({
                        type: "error",
                        message: res.message,
                    });
                }

            })
        }else{
            ElMessage({
                type: "error",
                message: "请勾选销售合同！",
            });
        }
    }
    // 分页
    const handleCurrentChange = (Num) => {
        constractorderList.value.pageNumber = Num;
        constractApply();
    };
    const defaltadd = ref()
    const searchForm = ref([]);
    // 待支付
    const router = useRouter();
    const handleUnpaid = async (item) => {
        let orderNumber = item.orderNumber;
        try {
            let data = await Timeout(orderNumber);
            let TimeNum = data.data.data.orderInfoPO.orderState;
            if (TimeNum === 1) {
                ElMessage.error("订单已超时");
                getgenerate();
            } else {
                router.push({ path: "payorder", query: { orderNumber: orderNumber } });
            }
        } catch (error) {
            console.log(error);
        }
    };
    // 补充订单信息
    let amendValue = ref();
    const handleReplenishment = (item) => {
        console.log(item, "补充订单信息");
        amendValue.value = item.orderNumber;
        IdValue.value = 3;
    };
    const submitForm = async (formEl) => {
        if (!formEl) return;
        await formEl.validate((valid, fields) => {
            if (valid) {
                if (ruleForm.id != null) {
                contractupdate(ruleForm).then((res) => {
                    if (res.code == 200) {
                    ElMessage({
                        message: "修改合同信息成功",
                        type: "success",
                    });
                    isShowBox.value = false;
                    getListAddress();
                    } else {
                    ElMessage({
                        message: res.message,
                        type: "error",
                    });
                    }
                });
                } else {
                delete ruleForm.id;
                contractupdate(ruleForm).then((res) => {
                    if (res.code == 200) {
                    ElMessage({
                        message: "新增合同信息成功",
                        type: "success",
                    });
                    isShowBox.value = false;
                    getListAddress();
                    } else {
                    ElMessage({
                        message: res.message,
                        type: "error",
                    });
                    }
                });
                }
            } else {
                console.log("error submit!", fields);
            }
        });
    };
    // 待审核
    let checkValue = ref();
    const handleShe = async (item) => {
        console.log(item.orderNumber);
        let orderNumber = item.orderNumber;
        let res = await PayTran(orderNumber);
        if (res.code === 200) {
            console.log(res.data);
            checkValue.value = res.data;
            dialogOpen.value = true;
        }
    };
    const setDefalut = (val) => {
        basiccontract(val.id).then((res) => {
        if (res.code == 200) {
            getListAddress();
        }
        });
    }
    const isShowBox = ref(false);
    const offTitle = () => {
        dialogOpen.value = false;
        getgenerate();
    };
    // 确认收货
    const confirmReceipt = async (item) => {
        let res = await ElMessageBox.confirm("是否提交当前订单已收货?", "", {
            confirmButtonText: "是",
            cancelButtonText: "否",
            type: "warning",
        }).catch((error) => {
            console.log(error);
        });
        if (res === "confirm") {
            console.log(item);
            let orderNumber = item.orderNumber;
            await UpdateOrder(orderNumber);
            getgenerate();
            ElMessage({
                type: "success",
                message: "提交成功",
            });
        }
    };
    onMounted(() => {
        getgenerate();
        getListAddress();
    });
</script>

<style lang="less" scoped>
    .add-text-sale-1 {
        display: flex;
        position: relative;
        left: 40px;
        top: 20px;

        .add-text-sale-2 {
            width: 16px;
            height: 16px;
            margin-top: 3px;
            margin-right: 6px;
            cursor: pointer;
        }

        .add-text-sale-3 {
            width: 345px;
            height: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #333333;
        }
    }

    .qOk1-1 {
        text-align: center;
        width: 180px;
        height: 52px;
        line-height: 52px;
        border-radius: 5px;
        border: 1px solid #AAAAAA;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #666666;
        cursor: pointer;
        margin-top: -6px;
    }

    .qOk2-1 {
        width: 180px;
        height: 52px;
        background: #ce1200;
        border-radius: 5px;
        line-height: 52px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #FFFFFF;
        text-align: center;
        margin-left: 24px;
        cursor: pointer;
        margin-top: -6px;
    }

    .add-text-sale {
        width: 1060px;
        height: 553px;
        background: #F7F7F7;
        margin-left: 40px;
        margin-top: -10px;
        overflow-y: scroll;
        .add-text-sale-plus{
            padding: 32px 0 16px 32px;
        }
    }

    .dialog-list-11 {
        width: 261px;
        height: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #666666;
        margin-left: 490px;
        margin-top: -8px;
    }

    .dialog-list-4 {
        margin: 19px 0 0 260px;
    }

    .border-content {
        display: flex;
        margin-left: 388px;
        margin-top: 48px;

        .qOk1 {
            text-align: center;
            width: 180px;
            height: 52px;
            line-height: 52px;
            border-radius: 5px;
            border: 1px solid #AAAAAA;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #666666;
            cursor: pointer;
        }

        .qOk2 {
            width: 180px;
            height: 52px;
            background: #ce1200;
            border-radius: 5px;
            line-height: 52px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #FFFFFF;
            text-align: center;
            margin-left: 24px;
            cursor: pointer;
        }
    }

    .content {
        .titletop {
            display: flex;
            margin-bottom: 11px;

            .contract {
                width: 98px;
                height: 23px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 24px;
                color: #333333;
            }

            .contractapply {
                width: 120px;
                height: 40px;
                background: #ce1200;
                border-radius: 5px;
                margin-left: 669px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
            }
        }

        .order-input {
            display: flex;
            justify-content: space-between;

            .order-content {

                // display: flex;
                .tabBox {
                    display: flex;
                    margin-top: 10px;

                    .tabItem {
                        font-size: 15px;
                        font-weight: 400;
                        color: #666666;
                        border-right: 1px solid #e1e1e1;
                        padding: 0 15px;
                        cursor: pointer;

                        &:first-child {
                            padding-left: 0;
                        }

                        &:last-child {
                            border-right: none;
                        }
                    }

                    .active {
                        //background: lighten(#ce1200, 50%);
                        color: #ce1200;
                    }

                    :hover {
                        //background: lighten(#ce1200, 50%);
                        color: #ce1200;
                    }
                }
            }

            .content-input {
                margin-right: 25px;
                width: 320px;

                ::v-deep [data-v-34d8de75] .el-input__wrapper {
                    background: transparent;
                    border: 1px solid #d9d9d9 !important;
                    border-radius: 5px;
                }

                ::v-deep .el-input__wrapper.is-focus {
                    background: transparent;
                    border: 1px solid #d9d9d9 !important;
                    border-radius: 5px;
                }

                .input-with-select {
                    height: 40px;

                    ::v-deep .el-input__inner {
                        padding-left: 10px !important;
                    }

                    .input-icon {
                        display: inline-block;
                        margin-bottom: 3px;

                        img {
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }

        .dialog-list {
            .dialog-list-1 {
                width: 216px;
                height: 19px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
            }
        }

        .content-theme {
            width: 884px;
            height: 292px;
            border: 1px solid #aaaaaa;
            position: relative;
            margin-top: 19px;

            .contract-1 {
                width: 72px;
                height: 17px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
                position: absolute;
                top: 30px;
                left: 32px;
            }

            .contract-2 {
                position: absolute;
                top: 32px;
                right: 31px;
                display: flex;

                .contract-2-1 {
                    width: 54px;
                    height: 18px;
                    font-family: Microsoft YaHei;
                    line-height: 18px;
                    font-weight: 400;
                    font-size: 18px;
                    color: #ce1200;
                    margin-left: 4px;
                }
            }

            .contract-3 {
                width: 373px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 74px;
                left: 32px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .contract-4 {
                width: 373px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 99px;
                left: 32px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .contract-5 {
                width: 373px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 124px;
                left: 32px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .contract-6 {
                width: 373px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 149px;
                left: 32px;
            }

            .contract-7 {
                width: 373px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 174px;
                left: 32px;
            }

            .contract-8 {
                width: 373px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 198px;
                left: 32px;
            }

            .contract-9 {
                width: 40px;
                height: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 124px;
                right: 414px;
            }

            .contract-9-1 {
                width: 334px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 124px;
                left: 510px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .contract-10 {
                width: 78px;
                height: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 148px;
                right: 375px;
            }

            .contract-10-1 {
                width: 334px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 148px;
                left: 510px;
            }

            .contract-11 {
                width: 78px;
                height: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 174px;
                right: 375px;
            }

            .contract-11-1 {
                width: 334px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 175px;
                left: 510px;
            }

            .contract-12 {
                width: 78px;
                height: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 199px;
                right: 375px;
            }

            .contract-12-1 {
                width: 334px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
                position: absolute;
                top: 199px;
                left: 510px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .contract-13 {
                width: 400px;
                height: 15px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #999999;
                position: absolute;
                bottom: 27px;
                left: 32px;
            }

            .contract-14 {
                width: 120px;
                height: 30px;
                border: 1px solid #aaaaaa;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #666666;
                text-align: center;
                line-height: 28px;
                position: absolute;
                bottom: 20px;
                right: 163px;
                cursor: pointer;
            }

            .contract-14:hover {
                color: #ce1200;
                border: 1px solid #ce1200;
            }

            .contract-15 {
                width: 120px;
                height: 30px;
                border: 1px solid #aaaaaa;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #666666;
                text-align: center;
                line-height: 28px;
                position: absolute;
                bottom: 20px;
                right: 31px;
                cursor: pointer;
            }

            .contract-15:hover {
                color: #ce1200;
                border: 1px solid #ce1200;
            }

            .customerorder-1 {
                width: 884px;
                margin-bottom: 31px;

                .customerorder-2 {
                    width: 766px;
                    height: 32px;
                    line-height: 32px;
                    display: flex;
                    margin: 1px 0 4px 87px;

                    .customerorder-3 {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 16px;
                        color: #333333;
                        margin-left: 6px;
                    }
                }

                .customerorder-4 {
                    width: 766px;
                    height: 78px;
                    background: #ffffff;
                    border-radius: 5px;
                    border: 1px solid #d9d9d9;
                    margin-left: 87px;
                    margin-bottom: 12px;
                    position: relative;

                    .customerorder-4-1 {
                        width: 309px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        font-size: 16px;
                        color: #333333;
                        position: absolute;
                        top: 28px;
                        left: 61px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .customerorder-4-2 {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 15px;
                        color: #666666;
                        position: absolute;
                        top: 28px;
                        left: 434px;
                    }

                    .customerorder-4-3 {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 15px;
                        color: #666666;
                        position: absolute;
                        top: 28px;
                        left: 496px;
                    }

                    .customerorder-4-4 {
                        position: absolute;
                        top: 14px;
                        right: 83px;
                    }

                    .customerorder-4-6 {
                        width: 129px;
                        height: 15px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 13px;
                        color: #ce1200;
                        position: absolute;
                        top: 48px;
                        right: 34px;
                    }

                    .customerorder-4-8 {
                        width: 100px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        background: #ffffff;
                        border-radius: 5px;
                        border: 1px solid #ce1200;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 12px;
                        color: #ce1200;
                        cursor: pointer;
                        position: absolute;
                        top: 12px;
                        right: 52px;
                    }

                    .customerorder-4-9 {
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }

                    .customerorder-4-10 {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 13px;
                        color: #f8392d;
                    }

                    .customerorder-5 {
                        width: 260px;
                        height: 70px;
                        margin: 58px 0 0 61px;
                        display: flex;

                        .customerorder-one {
                            height: 70px;
                            width: 108px;
                            margin-right: 44px;

                            .customerorder-5-1 {
                                width: 48px;
                                height: 48px;
                                border: 1px solid #e1e1e1;
                                margin: 0 auto;
                            }

                            .customerorder-5-2 {
                                text-align: center;
                                width: 108px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                font-size: 12px;
                                color: #999999;
                                margin-top: 4px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                        }
                    }

                    .customerorder-8-1 {
                        position: absolute;
                        top: 20px;
                        right: 6px;
                        height: 50px;
                        width: 60px;
                        align-items: center;
                        display: flex;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }

                    .customerorder-8 {
                        position: absolute;
                        top: 15px;
                        right: 78px;

                        .customerorder-8-2 {
                            width: 48px;
                            height: 48px;
                            border: 1px solid #e1e1e1;
                        }
                    }

                    .customerorder-6-3 {
                        position: absolute;
                        top: 38px;
                        right: 83px;
                    }

                    .customerorder-6-4 {
                        position: absolute;
                        top: 77px;
                        right: 26px;
                        width: 144px;
                        height: 35px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 13px;
                        color: #ce1200;
                        text-align: center;
                    }

                    .customerorder-6 {
                        width: 48px;
                        height: 70px;
                        margin-right: 24px;

                        .customerorder-6-1 {
                            width: 48px;
                            height: 48px;
                            border: 1px solid #e1e1e1;
                        }

                        .customerorder-6-2 {
                            margin-top: 4px;
                            text-align: center;
                            width: 48px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 12px;
                            color: #999999;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }

            .theme-1 {
                margin-left: 30px;

                img {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    margin-right: 5px;
                    margin-top: -5px;
                }

                span {
                    display: inline-block;
                    font-size: 18px;
                    margin-top: 20px;
                    font-weight: 400;
                    color: #ce1200;
                }
            }

            .theme-2 {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                width: 900px;

                .theme-3 {
                    margin-left: 31px;
                    display: flex;

                    .child1 {
                        font-size: 12px;
                        font-weight: 400;
                        color: #666666;
                        margin-right: 36px;
                    }

                    .child2 {
                        font-size: 12px;
                        font-weight: 400;
                        color: #666666;
                    }
                }

                .theme-4 {
                    margin-right: 31px;

                    .nth-1 {
                        font-size: 12px;
                        font-weight: 400;
                        color: #666666;
                        text-align: center;
                    }

                    .nth-2 {
                        display: inline-block;
                        font-size: 24px;
                        font-weight: bold;
                        color: #ce1200;
                        align-items: center;
                    }
                }
            }

            .border {
                border-bottom: 1px solid #e1e1e1;
            }

            .btn-price {
                display: flex;
                justify-content: space-between;

                .btn-price-1 {
                    display: flex;

                    .btn-img {
                        width: 67px;
                        height: 80px;
                        margin: 15px 26px 16px 32px;

                        img {
                            width: 67px;
                            height: 80px;
                        }
                    }

                    .btn-price-1-1 {
                        .price-p {
                            width: 370px;
                            font-size: 12px;
                            font-weight: 400;
                            color: #333333;
                            margin-top: 37px;
                            margin-bottom: 8px;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            overflow: hidden;
                        }

                        .btn-price-s {
                            display: flex;
                            align-items: baseline;

                            .price-d {
                                font-size: 12px;
                                font-weight: 400;
                                color: #333333;
                            }

                            .price-l {
                                font-size: 12px;
                                font-weight: 400;
                                color: #333333;
                                margin-left: 36px;
                            }

                            .price-e {
                                margin-left: 36px;
                                height: 18px;
                                line-height: 18px;

                                .price-e-1 {
                                    font-size: 12px;
                                    font-weight: 400;
                                    color: #333333;
                                }

                                .price-e-2 {
                                    font-size: 24px;
                                    font-weight: bold;
                                    color: #333333;
                                }
                            }
                        }
                    }
                }

                .btn-price-2 {
                    margin-right: 30px;

                    .btn-price-2-2 {
                        width: 120px;
                        height: 30px;
                        line-height: 28px;
                        border: 1px solid #aaaaaa;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        color: #666666;
                        margin-top: 10px;
                        cursor: pointer;
                        .hoverBorder();
                    }

                    .btn-price-2-3 {
                        width: 120px;
                        height: 30px;
                        line-height: 30px;
                        border: 1px solid #aaaaaa;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        color: #666666;
                        margin-top: 10px;
                        cursor: pointer;
                        .hoverBorder();
                    }
                }
            }
        }

        .demo-pagination-block {
            width: 980px;
            display: flex;
            justify-content: center;
            margin-top: 40px;

            .btn-cl {
                width: 65px;
                height: 35px;
                margin-left: 10px;
                font-size: 14px;
            }
        }
    }

    ::v-deep .el-input-group__append {
        background: #f2f2f2;
    }

    .dialog-list-1 {
        width: 216px;
        height: 19px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 18px;
        color: #333333;
        margin-bottom: 31px;
        margin-top: -11px;
    }

    .dialog-list-2 {
        display: flex;
        margin-bottom: 16px;

        .dialog-list-2-1 {
            margin-left: 40px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #333333;
        }

        .dialog-list-2-2 {
            margin-left: 40px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #333333;
        }

        .dialog-list-2-3 {
            margin-left: 147px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #333333;
        }

        .dialog-list-2-4 {
            margin-left: 271px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #333333;
        }

        .dialog-list-2-5 {
            margin-left: 40px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #333333;
        }

        .dialog-list-2-6 {
            margin-left: 40px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #333333;
        }

        .dialog-list-2-7 {
            margin-left: 40px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #333333;
        }
    }

    .dialog-list-3 {
        display: flex;
        margin-left: 38px;
        margin-bottom: 13px;

        .dialog-list-3-1 {
            width: 30px;
            text-align: center;
        }

        .dialog-list-3-2 {
            height: 20px;
            width: 165px;
            line-height: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #666666;
            margin-left: 42px;
        }

        .dialog-list-3-3 {
            width: 292px;
            height: 20px;
            line-height: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #666666;
            margin-left: 42px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .dialog-list-3-4 {
            width: 60px;
            height: 20px;
            line-height: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #666666;
            margin-left: 38px;
        }

        .dialog-list-3-5 {
            width: 60px;
            height: 20px;
            line-height: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #666666;
            margin-left: 41px;
        }

        .dialog-list-3-6 {
            width: 101px;
            height: 20px;
            line-height: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #666666;
            margin-left: 41px;
        }

        .dialog-list-3-7 {
            width: 141px;
            height: 20px;
            line-height: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #666666;
        }
    }

    ::v-deep .el-input__wrapper.is-focus {
        box-shadow: none;
        border: 1px solid #d9d9d9;
    }

    ::v-deep .el-divider--horizontal {
        margin: 0;
    }

    ::v-deep .el-input__wrapper {
        width: 240px;
        background-color: #f2f2f2f2;
    }

    ::v-deep .el-pagination.is-background .el-pager li {
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        background: transparent;
    }

    ::v-deep .el-pagination.is-background .el-pager li.is-active {
        background-color: #ce1200;
        color: #fff;
        border: 1px solid #ce1200;
    }

    ::v-deep .el-pager li:hover {
        color: #ce1200;
        border: 1px solid #ce1200;
    }

    ::v-deep .el-pagination button:hover {
        color: #ce1200;
        border: 1px solid #ce1200;
    }

    ::v-deep .el-pagination.is-background .btn-next {
        background: transparent;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }

    ::v-deep .el-pagination.is-background .btn-prev {
        background: transparent;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }

    ::v-deep .el-input__wrapper {
        background: transparent;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }

    /* ::v-deep .el-button:focus,
    .el-button:hover {
        color: #ce1200;
        border-color: #ce1200;
        background-color: transparent;
        outline: 0;
    } */
    .demo-ruleForm{
    margin-top: -30px;
    margin-bottom: -29px;
  }
  .plus {
        width: 390px;
        height: 234px;
        border: 1px dashed #d9d9d9;
        cursor: pointer;
        font-size: 13px;
        font-weight: 400;
        color: #999999;
        text-align: center;
        padding-top: 90px;
        background: #ffffff;
        img {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin-bottom: 12px;
        }
        margin-bottom: 20px;
        margin-right: 20px;
    }
  .gird-content {
    .gird-b {
        width: 145px;
        height: 23px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 24px;
        color: #333333;
    }
    .gird-plus {
      width: 824px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 32px;
      margin-left: 20px;
      .plus {
        width: 390px;
        height: 234px;
        border: 1px dashed #d9d9d9;
        cursor: pointer;
        font-size: 13px;
        font-weight: 400;
        color: #999999;
        text-align: center;
        padding-top: 90px;
        img {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin-bottom: 12px;
        }
        margin-bottom: 20px;
        margin-right: 20px;
      }
      .add-text {
        width: 390px;
        height: 234px;
        border: 1px solid #d9d9d9;
        padding-left: 29px;
        padding-bottom: 13px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        .content-text {
            .default{
                position: absolute;
                bottom: 18px;
                left: 18px;
                cursor: pointer;
            }
          .dz {
            display: flex;
            margin-top: 28px;
            .dz1 {
              width: 96px;
              height: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 13px;
              color: #666666;
            }
            .dz2 {
              width: 259px;
              height: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 13px;
              color: #333333;
            }
          }
          .ds{
            display: flex;
            margin-top: 12px;
            .ds1{
                width: 96px;
                height: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #666666;
            }
            .ds2{
                width: 259px;
                height: 13px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
            }
            .ds3{
                width: 238px;
                display: -webkit-box;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 13px;
                color: #333333;
            }
          }
        }
        .det-cet {
          display: flex;
          justify-content: end;
          align-items: baseline;
          margin-right: 13px;
          position: absolute;
          bottom: 16px;
          right: 28px;
          .det-cet-s {
            display: inline-block;
            width: 60px;
            height: 32px;
            line-height: 32px;
            background: #f2f2f2;
            border-radius: 5px;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            cursor: pointer;
          }
          .el-button {
            width: 60px;
            height: 32px;
            background: #f2f2f2;
          }
          .el-button:focus,
          .el-button:hover {
            color: #666666;
            border-color: #f2f2f2;
          }
          .det-cet-b {
            display: inline-block;
            width: 60px;
            height: 32px;
            line-height: 32px;
            background: #ce1200;
            border-radius: 5px;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .model-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  
    .addEd {
      width: 660px;
      height: 432px;
      background-color: #fff;
      position: absolute;
      top: 37%;
      right: 35%;
      margin-top: -150px;
      margin-left: -250px;
      border-radius: 5px;
      .addEd-title {
        width: 660px;
        height: 60px;
        background: #f2f2f2;
        border-radius: 5px 5px 0px 0px;
        span {
          display: inline-block;
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          margin: 21px 0 21px 20px;
        }
      }
      .el-form-item {
        width: 620px;
        height: 40px;
        margin: 21px 20px -6px 20px;
        .el-input {
          width: 620px;
          height: 40px;
          line-height: 40px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }
      .btn-all {
        display: flex;
        justify-content: end;
        margin-top: 102px;
        margin-right: 20px;
        .btn-rem {
          width: 160px;
          height: 40px;
          background: #aaaaaa;
          border: 0;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
        .btn-add {
          width: 160px;
          height: 40px;
          background: #ce1200;
          border: 0;
          margin-left: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
  .my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #f2f2f2;
    width: 660px;
    height: 60px;
    position: relative;
    left: -20px;
    top: -20px;
  }
  .open1 {
    width: 108px;
    height: 18px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin: 18px 0 0 20px;
  }
  .open2 {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-image: url("../../../assets/images/order/ch.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    position: absolute;
    right: 5px;
    margin: 23px 8px 0 0;
  }
  .open2:hover {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-image: url("../../../assets/images/order/hch.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 30px;
    height: 30px;
    border: none;
    position: absolute;
    right: 5px;
    margin: 15px 0px 0 0;
  }
  .open7 {
    border: none;
    width: 160px;
    height: 40px;
    background: #aaaaaa;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-left: 296px;
  }
  .open8 {
    border: none;
    width: 160px;
    height: 40px;
    background: #ce1200;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  ::v-deep .el-input__wrapper.is-focus {
    box-shadow: 0 0 0 1px #d9d9d9;
  }
  ::v-deep .el-input__wrapper {
    width: 540px;
    height: 40px;
    box-shadow: none;
    border: 1px solid #d9d9d9;
  }
  ::v-deep .el-input__inner {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
  }
  ::v-deep .el-cascader .el-input.is-focus .el-input__wrapper {
    box-shadow: 0 0 0 1px #d9d9d9;
  }
  ::v-deep .el-textarea__inner {
    height: 120px;
  }
  ::v-deep .el-form-item__content {
    flex-wrap: nowrap;
  }
    .add-text-plus {
        width: 900px;
        height: 560px;
        background: #F7F7F7;
        margin-left: 110px;
        margin-top: -10px;
        overflow: auto;

        .add-text-plus1 {
            margin: 36px 0 0 48px;
            display: flex;
            flex-wrap: wrap;

            .add-text {
                width: 390px;
                height: 234px;
                border: 1px solid #d9d9d9;
                padding-left: 29px;
                padding-bottom: 13px;
                margin-right: 20px;
                margin-bottom: 20px;
                position: relative;
                background: #FFFFFF;

                .content-text {
                    .default {
                        position: absolute;
                        bottom: 18px;
                        left: 18px;
                        cursor: pointer;
                    }

                    .dz {
                        display: flex;
                        margin-top: 28px;

                        .dz1 {
                            width: 96px;
                            height: 13px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 13px;
                            color: #666666;
                        }

                        .dz2 {
                            width: 259px;
                            height: 13px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 13px;
                            color: #333333;
                        }
                    }

                    .ds {
                        display: flex;
                        margin-top: 12px;

                        .ds1 {
                            width: 96px;
                            height: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 13px;
                            color: #666666;
                        }

                        .ds2 {
                            width: 259px;
                            height: 13px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 13px;
                            color: #333333;
                        }

                        .ds3 {
                            width: 238px;
                            display: -webkit-box;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 13px;
                            color: #333333;
                        }
                    }
                }

                .det-cet {
                    display: flex;
                    justify-content: end;
                    align-items: baseline;
                    margin-right: 13px;
                    position: absolute;
                    bottom: 16px;
                    right: 28px;

                    .det-cet-s {
                        display: inline-block;
                        width: 60px;
                        height: 32px;
                        line-height: 32px;
                        background: #f2f2f2;
                        border-radius: 5px;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        color: #666666;
                        cursor: pointer;
                    }

                    .el-button {
                        width: 60px;
                        height: 32px;
                        background: #f2f2f2;
                    }

                    .el-button:focus,
                    .el-button:hover {
                        color: #666666;
                        border-color: #f2f2f2;
                    }

                    .det-cet-b {
                        display: inline-block;
                        width: 60px;
                        height: 32px;
                        line-height: 32px;
                        background: #ce1200;
                        border-radius: 5px;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        color: #ffffff;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
            }
            .add-text-active {
                width: 390px;
                height: 234px;
                border: 1px solid #ce1200;
                padding-left: 29px;
                padding-bottom: 13px;
                margin-right: 20px;
                margin-bottom: 20px;
                position: relative;
                background: #FFFFFF;
                .hiddenImg {
                    background: url("../image/active.png") no-repeat;
                    width: 24px;
                    height: 24px;
                    float: right;
                    margin: -10px;
                }
                .content-text {
                    .default {
                        position: absolute;
                        bottom: 18px;
                        left: 18px;
                        cursor: pointer;
                    }

                    .dz {
                        display: flex;
                        margin-top: 28px;

                        .dz1 {
                            width: 96px;
                            height: 13px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 13px;
                            color: #666666;
                        }

                        .dz2 {
                            width: 259px;
                            height: 13px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 13px;
                            color: #333333;
                        }
                    }

                    .ds {
                        display: flex;
                        margin-top: 12px;

                        .ds1 {
                            width: 96px;
                            height: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 13px;
                            color: #666666;
                        }

                        .ds2 {
                            width: 259px;
                            height: 13px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 13px;
                            color: #333333;
                        }

                        .ds3 {
                            width: 238px;
                            display: -webkit-box;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            font-size: 13px;
                            color: #333333;
                        }
                    }
                }

                .det-cet {
                    display: flex;
                    justify-content: end;
                    align-items: baseline;
                    margin-right: 13px;
                    position: absolute;
                    bottom: 16px;
                    right: 28px;

                    .det-cet-s {
                        display: inline-block;
                        width: 60px;
                        height: 32px;
                        line-height: 32px;
                        background: #f2f2f2;
                        border-radius: 5px;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        color: #666666;
                        cursor: pointer;
                    }

                    .el-button {
                        width: 60px;
                        height: 32px;
                        background: #f2f2f2;
                    }

                    .el-button:focus,
                    .el-button:hover {
                        color: #666666;
                        border-color: #f2f2f2;
                    }

                    .det-cet-b {
                        display: inline-block;
                        width: 60px;
                        height: 32px;
                        line-height: 32px;
                        background: #ce1200;
                        border-radius: 5px;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        color: #ffffff;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
            }
        }

        .add-text-plus2 {
            position: absolute;
            bottom: 113px;
        }
    }
</style>