<template>
  <div class="gird-content">
    <p class="gird-b">发票信息</p>
    <div class="gird-plus">
      <div class="plus" @click="showBox">
        <img src="../../../assets/images/order/dz.png" alt="" />
        <p>新增发票信息</p>
      </div>
      <div
        v-for="(item, index) in InvoiceList"
        :key="item.id + index"
        class="top-all"
      >
        <div v-if="item.invoiceType == 0" class="top-all-text">
          <div class="top-1">{{ options[item.invoiceType].label }}</div>
          <div class="top-2">
            <span>发票抬头</span><span>{{ item.invoiceHead }}</span>
          </div>
          <div class="top-3">
            <span> 邮箱地址</span><span>{{ item.mailAddress }}</span>
          </div>
          <div class="top-4">
            <span>手机号</span><span class="top-7">{{ item.mobile }}</span>
          </div>
          <div class="default">
            <img v-if="item.isDefault == 1" src="../image/defalut.png">
            <img @click="setDefalut(item)" v-else src="../image/isdefalut.png">
          </div>
          <div class="top-btn">
            <span @click="deleteContent(item)">删除</span>
            <span @click="editContent(item)">编辑</span>
          </div>
        </div>
        <div v-if="item.invoiceType == 1" class="top-all-text">
          <div class="top-1">{{ options[item.invoiceType].label }}</div>
          <div class="top-6">
            <span>发票抬头</span><span>{{ item.invoiceHead }}</span>
          </div>
          <div class="top-7">
            <span>单位税号</span><span>{{ item.tariffLine }}</span>
          </div>
          <div class="top-8">
            <span>邮箱地址</span><span>{{ item.mailAddress }}</span>
          </div>
          <div class="default">
            <img v-if="item.isDefault == 1" src="../image/defalut.png">
            <img @click="setDefalut(item)" v-else src="../image/isdefalut.png">
          </div>
          <div class="top-btn">
            <span @click="deleteContent(item)">删除</span>
            <span @click="editContent(item)">编辑</span>
          </div>
        </div>
        <div v-if="item.invoiceType == 2" class="top-all-text">
          <div class="top-1">{{ options[item.invoiceType].label }}</div>
          <div class="top-201">
            <span>发票抬头</span><span>{{ item.invoiceHead }}</span>
          </div>
          <div class="top-201">
            <span>单位税号</span><span>{{ item.tariffLine }}</span>
          </div>
          <div class="top-20">
            <span>开户行</span><span>{{ item.bankName }}</span>
          </div>
          <div class="top-201">
            <span>银行账号</span><span>{{ item.bankAccount }}</span>
          </div>
          <div class="top-201">
            <span>企业电话</span><span>{{ item.businessMobile }}</span>
          </div>
          <div class="top-201-1">
            <div class="dz1">企业地址</div>
            <div class="dz2">{{ item.businessAddress }}</div>
          </div>
          <div class="default">
            <img v-if="item.isDefault == 1" src="../image/defalut.png">
            <img @click="setDefalut(item)" v-else src="../image/isdefalut.png">
          </div>
          <div class="top-btn1">
            <span @click="deleteContent(item)">删除</span>
            <span @click="editContent(item)">编辑</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <el-dialog v-model="open" :show-close="false" @close="clean" width="41.25rem">
      <template #header="{ close, titleId, titleClass }">
        <div class="my-header">
          <h4 :id="titleId" :class="titleClass" class="open1">{{ title }}</h4>
          <span @click="close" class="open2"> </span>
        </div>
      </template>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="1px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label=" " prop="invoiceType">
          <el-select v-model="ruleForm.invoiceType" placeholder="选择发票类型">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <div v-if="ruleForm.invoiceType == 0">
          <el-form-item label=" " prop="invoiceHead">
            <el-input
              class="open4"
              v-model="ruleForm.invoiceHead"
              placeholder="发票抬头"
            />
          </el-form-item>
          <el-form-item label=" " prop="mailAddress">
            <el-input
              class="open6"
              v-model="ruleForm.mailAddress"
              placeholder="邮箱地址"
            />
          </el-form-item>
          <el-form-item label=" " prop="mobile">
            <el-input
              class="open6"
              v-model="ruleForm.mobile"
              placeholder="手机号"
            />
          </el-form-item>
        </div>
        <div v-if="ruleForm.invoiceType == 1">
          <el-form-item label=" " prop="invoiceHead">
            <el-input
              class="open4"
              v-model="ruleForm.invoiceHead"
              placeholder="发票抬头"
            />
          </el-form-item>
          <el-form-item label=" " prop="tariffLine">
            <el-input
              class="open6"
              v-model="ruleForm.tariffLine"
              placeholder="单位税号"
            />
          </el-form-item>
          <el-form-item label=" " prop="mailAddress">
            <el-input
              class="open6"
              v-model="ruleForm.mailAddress"
              placeholder="邮箱地址"
            />
          </el-form-item>
        </div>
        <div v-if="ruleForm.invoiceType == 2">
          <el-form-item label=" " prop="invoiceHead">
            <el-input
              class="open4"
              v-model="ruleForm.invoiceHead"
              placeholder="发票抬头"
            />
          </el-form-item>
          <el-form-item label=" " prop="tariffLine">
            <el-input
              class="open6"
              v-model="ruleForm.tariffLine"
              placeholder="单位税号"
            />
          </el-form-item>
          <el-form-item label=" " prop="bankName">
            <el-input
              class="open6"
              v-model="ruleForm.bankName"
              placeholder="开户行"
            />
          </el-form-item>
          <el-form-item label=" " prop="bankAccount">
            <el-input
              class="open6"
              v-model="ruleForm.bankAccount"
              placeholder="银行账号"
            />
          </el-form-item>
          <el-form-item label=" " prop="businessMobile">
            <el-input
              class="open6"
              v-model="ruleForm.businessMobile"
              placeholder="企业电话"
            />
          </el-form-item>
          <el-form-item label=" " prop="businessAddress">
            <el-input
              class="open6"
              v-model="ruleForm.businessAddress"
              placeholder="企业地址"
            />
          </el-form-item>
        </div>
        <el-form-item style="margin-top: 18px">
          <el-button class="open7" @click="closeForm()"> 取消 </el-button>
          <el-button class="open8" @click="submitForm(ruleFormRef)"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive } from "vue";
import { listInvoice, saveInvoice } from "@/api/order";
import { DeleteAddressInvoice } from "../../../api/orderCenter/index.js";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  defaultInvoice
} from "@/api/order";
const open = ref(false);
const title = ref();
const ruleFormRef = ref();
const ruleForm = reactive({
  invoiceType: "0",
  invoiceHead: "",
  mailAddress: "",
  mobile: "",
  tariffLine: "",
  bankName: "",
  bankAccount: "",
  businessMobile: "",
  businessAddress: "",
});
// 拉下框
const options = [
  {
    value: "0",
    label: "个人发票",
  },
  {
    value: "1",
    label: "企业发票---普通发票",
  },
  {
    value: "2",
    label: "企业发票---增值税专用发票",
  },
];
const clean = () => {
  ruleForm.invoiceHead = null;
  ruleForm.mailAddress = null;
  ruleForm.mobile = null;
  ruleForm.tariffLine = null;
  ruleForm.bankName = null;
  ruleForm.bankAccount = null;
  ruleForm.businessMobile = null;
  ruleForm.businessAddress = null;
  return;
  ruleFormRef.value?.resetFields();
};
const setDefalut = (val) => {
  defaultInvoice(val.id).then((res) => {
    if (res.code == 200) {
      listInvoiceFn();
    }
  });
}
// 删除
const deleteContent = async (item) => {
  let res = await ElMessageBox.confirm("是否删除当前发票?", "", {
    confirmButtonText: "是",
    cancelButtonText: "否",
    type: "warning",
  }).catch((error) => {
    console.log(error);
  });
  if (res == "confirm") {
    console.log(item.id);
    let id = item.id;
    await DeleteAddressInvoice(id);
    listInvoiceFn();
    ElMessage({
      type: "success",
      message: "删除成功",
    });
  }
};
// 编辑
const editContent = (item) => {
  clean();
  title.value = "修改发票信息";
  if (item.invoiceType == 0) {
    ruleForm.invoiceType = "0";
    ruleForm.invoiceHead = item.invoiceHead;
    ruleForm.mailAddress = item.mailAddress;
    ruleForm.mobile = item.mobile;
    InvoiceId.value = item.id;
    open.value = true;
  } else if (item.invoiceType == 1) {
    ruleForm.invoiceType = "1";
    ruleForm.invoiceHead = item.invoiceHead;
    ruleForm.mailAddress = item.mailAddress;
    ruleForm.tariffLine = item.tariffLine;
    InvoiceId.value = item.id;
    open.value = true;
  } else {
    ruleForm.invoiceType = "2";
    ruleForm.invoiceHead = item.invoiceHead;
    ruleForm.tariffLine = item.tariffLine;
    ruleForm.bankName = item.bankName;
    ruleForm.bankAccount = item.bankAccount;
    ruleForm.businessMobile = item.businessMobile;
    ruleForm.businessAddress = item.businessAddress;
    InvoiceId.value = item.id;
    open.value = true;
  }
  open.value = true;
};
// 全部数据
const InvoiceList = ref([]);
const listInvoiceFn = async () => {
  InvoiceList.value = [];
  try {
    const data = await listInvoice();
    InvoiceList.value = data.data;
  } catch (error) {
    console.log(error);
  }
};
// 验证规则
const rules = reactive({
  invoiceType: [{ required: true, message: "请选择发票类型", trigger: "blur" }],
  invoiceHead: [
    { required: true, message: "请输入发票抬头", trigger: "blur" },
    { max: 30, message: "长度不能超过30位" },
  ],
  mailAddress: [
    { required: true, message: "请输入邮箱地址", trigger: "blur" },
    {
      pattern: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
      message: "请输入正确的邮箱地址",
      trigger: "blur",
    },
  ],
  tariffLine: [
    { required: true, message: "请输入单位税号", trigger: "blur" },
    { max: 30, message: "长度不能超过30位" },
  ],
  bankName: [{ required: true, message: "请输入开户行", trigger: "blur" }],
  bankAccount: [
    { required: true, message: "请输入银行账号", trigger: "blur" },
    { max: 30, message: "长度不能超过30位" },
  ],
  businessMobile: [
    { required: true, message: "请输入企业电话", trigger: "blur" },
    { max: 30, message: "长度不能超过30位" },
  ],
  businessAddress: [
    { required: true, message: "请输入企业地址", trigger: "blur" },
  ],
  mobile: [
    { required: true, message: "请输入手机号", trigger: "blur" },
    {
      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      message: "请输入正确的手机号码",
      trigger: "blur",
    },
  ],
});
// 取消按钮
const closeForm = () => {
  open.value = false;
};

// 确定按钮
const InvoiceId = ref(null);
const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    console.log(valid, fields);
    if (valid) {
      if (InvoiceId.value == null) {
        if (ruleForm.invoiceType == 0) {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            mailAddress: ruleForm.mailAddress,
            mobile: ruleForm.mobile,
          };
          saveInvoiceFn(data, 0);
        } else if (ruleForm.invoiceType == 1) {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            mailAddress: ruleForm.mailAddress,
            tariffLine: ruleForm.tariffLine,
          };
          saveInvoiceFn(data, 0);
        } else {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            tariffLine: ruleForm.tariffLine,
            bankName: ruleForm.bankName,
            bankAccount: ruleForm.bankAccount,
            businessAddress: ruleForm.businessAddress,
            businessMobile: ruleForm.businessMobile,
          };
          saveInvoiceFn(data, 0);
        }
      } else {
        if (ruleForm.invoiceType == 0) {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            mailAddress: ruleForm.mailAddress,
            mobile: ruleForm.mobile,
            id: InvoiceId.value,
          };
          saveInvoiceFn(data, 1);
        } else if (ruleForm.invoiceType == 1) {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            mailAddress: ruleForm.mailAddress,
            tariffLine: ruleForm.tariffLine,
            id: InvoiceId.value,
          };
          saveInvoiceFn(data, 1);
        } else {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            tariffLine: ruleForm.tariffLine,
            bankName: ruleForm.bankName,
            bankAccount: ruleForm.bankAccount,
            businessAddress: ruleForm.businessAddress,
            businessMobile: ruleForm.businessMobile,
            id: InvoiceId.value,
          };
          saveInvoiceFn(data, 1);
        }
      }
	    listInvoiceFn();
    } else {
      console.log("error submit!", fields);
    }
  });
};
// 新增发票
const saveInvoiceFn = (data, val) => {
  console.log(data, val);
  if (val == 0) {
    delete data.id;
    saveInvoice(data).then((res) => {
      if (res.code == 200) {
        ElMessage({
          message: "新增发票成功",
          type: "success",
        });
        InvoiceId.value = null;
        open.value = false;
        listInvoiceFn();
      }
    });
  } else {
    saveInvoice(data).then((res) => {
      if (res.code == 200) {
        ElMessage({
          message: "修改发票成功",
          type: "success",
        });
        InvoiceId.value = null;
        open.value = false;
        listInvoiceFn();
      }
    });
  }
};
onMounted(() => {
  listInvoiceFn();
});
const showBox = () => {
  ruleForm.bankAccount = "";
  ruleForm.bankName = "";
  ruleForm.businessAddress = "";
  ruleForm.businessMobile = "";
  ruleForm.invoiceHead = "";
  ruleForm.mailAddress = "";
  ruleForm.mobile = "";
  ruleForm.tariffLine = "";
  InvoiceId.value = null;
  ruleForm.invoiceType = "0";
  open.value = true;
  title.value = "新增发票信息";
};
</script>

<style lang="less" scoped>
.default{
  position: absolute;
  cursor: pointer;
  bottom: 15px;
  left: 22px;
}
.gird-content {
  .gird-b {
    font-size: 28px;
    font-weight: 400;
    color: #666666;
  }
  .gird-plus {
    width: 720px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    margin-left: 20px;
    .plus {
      width: 340px;
      height: 330px;
      border: 1px dashed #d9d9d9;
      cursor: pointer;
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      text-align: center;
      padding-top: 120px;
      img {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-bottom: 12px;
      }
      margin-bottom: 20px;
      margin-right: 20px;
    }
    .top-all {
      width: 340px;
      height: 330px;
      margin-right: 20px;
      margin-bottom: 20px;
      position: relative;
      .top-all-text {
        width: 340px;
        height: 330px;
        border: 1px solid #d9d9d9;

        .top-1 {
          font-size: 16px;
          font-weight: 400;
          color: #111111;
          margin-top: 30px;
          text-align: center;
          margin-bottom: 32px;
        }
        .top-2,
        .top-6,
        .top-7,
        .top-8 {
          margin-left: 30px;
          margin-bottom: 15px;
          :nth-child(1) {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-right: 31px;
          }
          :nth-child(2) {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
        .top-3 {
          margin-left: 30px;
          margin-bottom: 15px;
          :nth-child(1) {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-right: 31px;
          }
          :nth-child(2) {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
        .top-4 {
          margin-left: 30px;
          margin-bottom: 15px;
          :nth-child(1) {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-right: 16px;
          }
          :nth-child(2) {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
        .top-btn {
          position: relative;
          top: 85px;
          left: 200px;
          :nth-child(1) {
            display: inline-block;
            width: 60px;
            height: 32px;
            line-height: 32px;
            background: #f2f2f2;
            border-radius: 5px;
            text-align: center;
            cursor: pointer;
          }
          :nth-child(2) {
            display: inline-block;
            width: 60px;
            height: 32px;
            line-height: 32px;
            background: #ce1200;
            border-radius: 5px;
            text-align: center;
            color: #fff;
            margin-left: 8px;
            cursor: pointer;
          }
        }
        .top-20 {
          margin-left: 30px;
          margin-bottom: 8px;
          :nth-child(1) {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-right: 44px;
          }
          :nth-child(2) {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
        .top-201 {
          margin-left: 30px;
          margin-bottom: 8px;
          :nth-child(1) {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-right: 31px;
          }
          :nth-child(2) {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
        .top-201-1 {
          display: flex;
          margin-bottom: 21px;
          .dz1 {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-right: 31px;
            margin-left: 31px;
          }
          .dz2 {
            width: 200px;
            height: 36px;
            font-size: 13px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .top-btn1 {
          position: relative;
          top: -12px;
          left: 200px;
          :nth-child(1) {
            display: inline-block;
            width: 60px;
            height: 32px;
            line-height: 32px;
            background: #f2f2f2;
            border-radius: 5px;
            text-align: center;
            cursor: pointer;
          }
          :nth-child(2) {
            display: inline-block;
            width: 60px;
            height: 32px;
            line-height: 32px;
            background: #ce1200;
            border-radius: 5px;
            text-align: center;
            color: #fff;
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f2f2f2;
  width: 660px;
  height: 60px;
  position: relative;
  left: -16px;
  top: -20px;
}
::v-deep .el-cascader .el-input .el-input__inner {
  text-overflow: ellipsis;
  cursor: pointer;
  height: 40px;
  width: 563px;
}
.demo-ruleForm{
  margin-top: -30px;
  margin-bottom: -30px;
}
::v-deep .el-select .el-input__wrapper {
  cursor: pointer;
  width: 608px;
  height: 39px !important;
}
::v-deep .el-select__wrapper {
  height: 40px;
}
.open1 {
  width: 108px;
  height: 18px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 18px 0 0 20px;
}
.open2 {
  display: inline-block;
  background: url("../../../assets/images/order/ch.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 5px;
  margin: 23px 8px 0 0;
}
.open2:hover {
  display: inline-block;
  background: url("../../../assets/images/order/hch.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 5px;
  margin: 15px 0px 0 0;
}
.open3 {
  height: 40px;
}
.open4 {
  height: 40px;
}
.open5 {
  height: 40px;
}
.open6 {
  height: 40px;
}

.open7 {
  border: none;
  width: 160px;
  height: 40px;
  background: #aaaaaa;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-left: 296px;
}
.open8 {
  border: none;
  width: 160px;
  height: 40px;
  background: #ce1200;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
::v-deep .el-form-item__content {
  flex-wrap: nowrap;
}
</style>
