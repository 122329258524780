<template>
  <div class="gird-content">
    <p class="gird-b">收货地址</p>
    <div class="gird-plus">
      <div class="plus" @click="showBox">
        <img src="../../../assets/images/order/dz.png" alt="" />
        <p>新增收货地址</p>
      </div>
      <div
        class="add-text"
        v-for="(item, index) in addressList"
        :key="item.id + index"
      >
        <div class="content-text">
          <p>
            收货人：<b>{{ item.receiver }}</b>
          </p>
          <p>电话：{{ item.mobile }}</p>
          <div class="dz">
            <div class="dz1">地址：</div>
            <div class="dz2">
              {{
                provinceFilter(addressCity, item.province) +
                cityFilter(addressArea, item.city) +
                districtFilter(addressCount, item.district) +
                streetFilter(addressStreet, item.street) +
                item.detail
              }}
            </div>
          </div>
          <div class="default">
            <img v-if="item.isDefault == 1" src="../image/defalut.png" />
            <img
              @click="setDefalut(item)"
              v-else
              src="../image/isdefalut.png"
            />
          </div>
        </div>
        <div class="det-cet">
          <span @click="delItem(item)" class="det-cet-s">删除</span>
          <span @click="upDataItem(item)" class="det-cet-b">编辑</span>
        </div>
      </div>
    </div>
  </div>
  <div>
    <el-dialog
      v-model="isShowBox"
      :show-close="false"
      @close="clean"
      width="41.25rem"
    >
      <template #header="{ close, titleId, titleClass }">
        <div class="my-header">
          <h4 :id="titleId" :class="titleClass" class="open1">{{ title }}</h4>
          <span @click="close" class="open2"> </span>
        </div>
      </template>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="1px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label=" " prop="receiver">
          <el-input
            class="open3"
            v-model="ruleForm.receiver"
            placeholder="姓名"
          />
        </el-form-item>
        <el-form-item label=" " prop="mobile">
          <el-input
            class="open4"
            v-model="ruleForm.mobile"
            placeholder="手机号"
          />
        </el-form-item>
        <el-form-item label=" " prop="address">
          <div class="m-4">
            <el-cascader
              placeholder="选择地址"
              v-model="ruleForm.address"
              :options="options"
              filterable
              clearable
            />
          </div>
        </el-form-item>
        <el-form-item label=" " prop="detail">
          <el-input
            type="textarea"
            :rows="4"
            class="open6"
            v-model="ruleForm.detail"
            placeholder="详细地址"
            @change="chooseAddress"
          />
        </el-form-item>
        <el-form-item style="margin-top: 18px">
          <el-button class="open7" @click="RefMove()">取消 </el-button>
          <el-button class="open8" @click="submitForm(ruleFormRef)"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive } from "vue";
import { listAddress, addEditAddress } from "@/api/order";
import { DeleteAddress } from "../../../api/orderCenter/index.js";
// import ShippingAddress from "./ShippingAddress";
import Address from "@/utils/address.json";
import { ElMessage, ElMessageBox } from "element-plus";
import { defaultAddress } from "@/api/order";
const isShowBox = ref(false);

// 验证
const ruleFormRef = ref();
const title = ref();
// 数据
const ruleForm = reactive({
  type: 0,
  address: "",
  representative: "",
  phoneNumber: "",
  partyA: "",
});
// 验证规则
const rules = reactive({
  receiver: [{ required: true, message: "请输入姓名", trigger: "blur" }],
  mobile: [
    { required: true, message: "请输入手机号", trigger: "blur" },
    {
      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      message: "请输入正确的手机号码",
      trigger: "blur",
    },
  ],
  address: [{ required: true, message: "请选择地址", trigger: "blur" }],
  detail: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
});
const setDefalut = (val) => {
  defaultAddress(val.id).then((res) => {
    if (res.code == 200) {
      getListAddress();
    }
  });
};
// 关闭
const RefMove = () => {
  isShowBox.value = false;
  getListAddress();
};
// 新增和编辑
const addressId = ref(null);
const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log(addressId.value);
      if (addressId.value != null) {
        ruleForm.province = ruleForm.address[0];
        ruleForm.city = ruleForm.address[1];
        ruleForm.district = ruleForm.address[2];
        ruleForm.street = ruleForm.address[3];
        ruleForm.id = addressId.value;
        delete ruleForm.address;
        addressId.value = null;
        addEditAddress(ruleForm).then((res) => {
          if (res.code == 200) {
            ElMessage({
              message: "修改地址成功",
              type: "success",
            });
            isShowBox.value = false;
            getListAddress();
          } else {
            ElMessage({
              message: res.message,
              type: "error",
            });
          }
        });
      } else {
        ruleForm.province = ruleForm.address[0];
        ruleForm.city = ruleForm.address[1];
        ruleForm.district = ruleForm.address[2];
        ruleForm.street = ruleForm.address[3];
        delete ruleForm.id;
        delete ruleForm.address;
        addEditAddress(ruleForm).then((res) => {
          if (res.code == 200) {
            ElMessage({
              message: "新增地址成功",
              type: "success",
            });
            isShowBox.value = false;
            getListAddress();
          } else {
            ElMessage({
              message: res.message,
              type: "error",
            });
          }
        });
      }
    } else {
      console.log("error submit!", fields);
    }
  });
};
// 省区市转换成对应的城市名
const options = reactive([]); // 省区市数据
const chooseAddress = () => {
  Address.forEach((item1, index1) => {
    options.push({
      value: item1.code,
      label: item1.name,
      children: [],
    });
    if (item1.children) {
      item1.children.forEach((item2, index2) => {
        if (item2.children) {
          options[index1].children.push({
            value: item2.code,
            label: item2.name,
            children: [],
          });
        } else {
          options[index1].children.push({
            value: item2.code,
            label: item2.name,
          });
        }
        if (item2.children) {
          item2.children.forEach((item3, index3) => {
            if (item3.children) {
              options[index1].children[index2].children.push({
                value: item3.code,
                label: item3.name,
                children: [],
              });
            } else {
              options[index1].children[index2].children.push({
                value: item3.code,
                label: item3.name,
              });
            }
            if (item3.children) {
              item3.children.forEach((item4) => {
                options[index1].children[index2].children[index3].children.push(
                  {
                    value: item4.code,
                    label: item4.name,
                  }
                );
              });
            }
          });
        }
      });
    }
  });
};
const clean = () => {
  ruleForm.mobile = null;
  ruleForm.receiver = null;
  ruleForm.detail = null;
  ruleForm.address = [];
  return;
  ruleFormRef.value?.resetFields();
};
// 打开
const showBox = () => {
  ruleForm.receiver = "";
  ruleForm.mobile = "";
  ruleForm.address = [];
  ruleForm.detail = "";
  ruleForm.province = "";
  ruleForm.city = "";
  ruleForm.district = "";
  ruleForm.street = "";
  isShowBox.value = true;
  addressId.value = null;
  title.value = "新增收货地址";
};
// 删除
const delItem = async (item) => {
  let res = await ElMessageBox.confirm("是否删除当前地址?", {
    confirmButtonText: "是",
    cancelButtonText: "否",
    type: "warning",
  }).catch((error) => {
    console.log(error);
  });
  if (res == "confirm") {
    console.log(item.id);
    let id = item.id;
    await DeleteAddress(id);
    getListAddress();
    ElMessage({
      type: "success",
      message: "删除成功",
    });
  }
};

const upDataItem = (item) => {
  clean();
  title.value = "修改收货地址";
  ruleForm.mobile = item.mobile;
  ruleForm.receiver = item.receiver;
  ruleForm.detail = item.detail;
  ruleForm.address.push(item.province.toString());
  ruleForm.address.push(item.city.toString());
  ruleForm.address.push(item.district.toString());
  ruleForm.address.push(item.street.toString());
  addressId.value = item.id;
  isShowBox.value = true;
};

// 关闭
// const clickColes = () => {
//   isShowBox.value = false;
//   getListAddress();
// };

// 页面显示数据
const addressList = ref([]);
const getListAddress = async () => {
  try {
    let data = await listAddress();
    addressList.value = data.data;
    console.log(addressList.value, "数据");
    // data.data.forEach((el) => {
    //   if (el.isDefault == 1) {
    //     addressDeafault.value = el;
    //   }
    // });
  } catch (error) {
    console.log(error);
  }
};
const addressCity = ref([]);
const addressArea = ref([]);
const addressCount = ref([]);
const addressStreet = ref([]);
const addressQuery = () => {
  Address.forEach((city) => {
    addressCity.value.push({
      code: city.code,
      name: city.name,
    });
    if (city.children) {
      city.children.forEach((area) => {
        addressArea.value.push({
          code: area.code,
          name: area.name,
        });
        if (area.children) {
          area.children.forEach((count) => {
            addressCount.value.push({
              code: count.code,
              name: count.name,
            });
            if (count.children) {
              count.children.forEach((street) => {
                addressStreet.value.push({
                  code: street.code,
                  name: street.name,
                });
              });
            }
          });
        }
      });
    }
  });
};
const provinceFilter = (addressCity, value) => {
  let obj = value;
  const val = addressCity.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const cityFilter = (addressArea, value) => {
  let obj = value;
  const val = addressArea.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const districtFilter = (addressCount, value) => {
  let obj = value;
  const val = addressCount.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const streetFilter = (addressStreet, value) => {
  let obj = value;
  const val = addressStreet.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
onMounted(() => {
  getListAddress();
  addressQuery();
  chooseAddress();
});
</script>

<style lang="less" scoped>
.default {
  position: absolute;
  bottom: -58px;
  left: -8px;
  cursor: pointer;
}
.demo-ruleForm {
  margin-top: -30px;
  margin-bottom: -29px;
}
.gird-content {
  .gird-b {
    font-size: 28px;
    font-weight: 400;
    color: #666666;
  }
  .gird-plus {
    width: 690px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    margin-left: 20px;
    .plus {
      width: 320px;
      height: 200px;
      border: 1px dashed #d9d9d9;
      cursor: pointer;
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      text-align: center;
      padding-top: 73px;
      img {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-bottom: 12px;
      }
      margin-bottom: 20px;
      margin-right: 20px;
    }
    .add-text {
      width: 320px;
      height: 200px;
      border: 1px solid #d9d9d9;
      padding-left: 31px;
      padding-bottom: 13px;
      margin-right: 20px;
      margin-bottom: 20px;
      .content-text {
        position: relative;
        :nth-child(1) {
          margin-top: 30px;
          margin-bottom: 25px;
          font-size: 16px;
          font-weight: 400;
          color: #111111;
        }
        :nth-child(2) {
          font-size: 13px;
          font-weight: 400;
          color: #666666;
          margin-bottom: 11px;
        }
        .dz {
          display: flex;
          margin-top: 15px;
        }
        .dz1 {
          width: 40px;
          margin-top: 0;
          font-size: 13px;
          color: #666666;
          margin-bottom: 20px;
        }
        .dz2 {
          font-size: 13px;
          font-weight: 400;
          color: #666666;
          width: 250px;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .det-cet {
        display: flex;
        justify-content: end;
        align-items: baseline;
        margin-right: 13px;
        // margin-top: 12px;
        .det-cet-s {
          display: inline-block;
          width: 60px;
          height: 32px;
          line-height: 32px;
          background: #f2f2f2;
          border-radius: 5px;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
        }
        .el-button {
          width: 60px;
          height: 32px;
          background: #f2f2f2;
        }
        .el-button:focus,
        .el-button:hover {
          color: #666666;
          border-color: #f2f2f2;
        }
        .det-cet-b {
          display: inline-block;
          width: 60px;
          height: 32px;
          line-height: 32px;
          background: #ce1200;
          border-radius: 5px;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
.model-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  .addEd {
    width: 660px;
    height: 432px;
    background-color: #fff;
    position: absolute;
    top: 37%;
    right: 35%;
    margin-top: -150px;
    margin-left: -250px;
    border-radius: 5px;
    .addEd-title {
      width: 660px;
      height: 60px;
      background: #f2f2f2;
      border-radius: 5px 5px 0px 0px;
      span {
        display: inline-block;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        margin: 21px 0 21px 20px;
      }
    }
    .el-form-item {
      width: 620px;
      height: 40px;
      margin: 21px 20px -6px 20px;
      .el-input {
        width: 620px;
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
    .btn-all {
      display: flex;
      justify-content: end;
      margin-top: 102px;
      margin-right: 20px;
      .btn-rem {
        width: 160px;
        height: 40px;
        background: #aaaaaa;
        border: 0;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
      .btn-add {
        width: 160px;
        height: 40px;
        background: #ce1200;
        border: 0;
        margin-left: 16px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }
    // .open2 {
    //   display: inline-block;
    //   width: 14px;
    //   height: 14px;
    //   background-image: url("../../../assets/images/order/ch.png");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   position: absolute;
    //   top: 23px;
    //   right: 22px;
    //   cursor: pointer;
    //   &:hover {
    //     width: 30px;
    //     height: 30px;
    //     background-image: url("../../../assets/images/order/hch.png");
    //     position: absolute;
    //     top: 16px;
    //     right: 14px;
    //   }
    // }
  }
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f2f2f2;
  width: 660px;
  height: 60px;
  position: relative;
  left: -16px;
  top: -20px;
}
.open1 {
  width: 108px;
  height: 18px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 18px 0 0 20px;
}
.open2 {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url("../../../assets/images/order/ch.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  position: absolute;
  right: 5px;
  margin: 23px 8px 0 0;
}
.open2:hover {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url("../../../assets/images/order/hch.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
  border: none;
  position: absolute;
  right: 5px;
  margin: 15px 0px 0 0;
}
.open7 {
  border: none;
  width: 160px;
  height: 40px;
  background: #aaaaaa;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-left: 296px;
}
.open8 {
  border: none;
  width: 160px;
  height: 40px;
  background: #ce1200;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
::v-deep .el-select__wrapper {
  height: 40px;
}
::v-deep .el-input__wrapper.is-focus {
  box-shadow: 0 0 0 1px #d9d9d9;
}
::v-deep .el-input__wrapper {
  width: 620px;
  height: 40px;
  box-shadow: none;
  border: 1px solid #d9d9d9;
}
::v-deep .el-input__inner {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
::v-deep .el-cascader .el-input.is-focus .el-input__wrapper {
  box-shadow: 0 0 0 1px #d9d9d9;
}
::v-deep .el-textarea__inner {
  height: 120px;
}
::v-deep .el-form-item__content {
  flex-wrap: nowrap;
}
</style>
